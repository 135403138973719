
import { Component, Vue } from 'vue-property-decorator';
import CyFiTable from './CyFiScoresTable.vue';

@Component({ components: { CyFiTable } })
export default class CyFiPage extends Vue {
  colorOptionsBasic = [
    { color: '#ADADAD', condition: (val: number) => isNaN(val) },
    { color: '#E65049', condition: (val: number) => val >= 0 && val <= 2 },
    { color: '#F29A53', condition: (val: number) => val > 2 && val < 4 },
    { color: '#3D894C', condition: (val: number) => val >= 4 && val <= 5 },
  ];
}
