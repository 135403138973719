
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextScoreLabeled extends Vue {
  @Prop() value!: number;
  @Prop() label!: string;

  get colorClass() {
    if (typeof this.value === 'number') {
      return {
        high: this.value >= 0 && this.value < 3,
        medium: this.value >= 3 && this.value < 4,
        low: this.value >= 4,
      };
    }
    return {};
  }

  get textValue(): string {
    if (typeof this.value === 'number') {
      if (this.value >= 0 && this.value < 3) return 'high';
      if (this.value >= 3 && this.value < 4) return 'medium';
      if (this.value >= 4) return 'low';
    }
    return '-';
  }
}
