
import { Component, Vue } from 'vue-property-decorator';
import TextScoreLabeled from '@/components/customer/TextScoreLabeled.vue';
import TextValueLabeledStatic from '@/components/customer/TextValueLabeledStatic.vue';
import MarkdownViewer from './markdown-viewer/MarkdownViewer.vue';
import AlertContent from './alert-section/index.vue';
import CorporateContent from './corporate-section/index.vue';

@Component({
  components: {
    TextScoreLabeled,
    TextValueLabeledStatic,
    MarkdownViewer,
    AlertContent,
    CorporateContent,
  },
})
export default class OverviewPage extends Vue {
  stockDataIsLoading: boolean = false;

  get widgetData() {
    const data = {
      Recon: '-',
      Attack: '-',
      Exfil: '-',
    };
    if (this.factorsData) {
      data.Recon = this.factorsData.Recon.value;
      data.Attack = this.factorsData.Attack.value;
      data.Exfil = this.factorsData.Exfil.value;
    }
    return data;
  }

  get secondTriggerCondition() {
    const getRange = (value: number) => {
      if (value >= 0 && value < 3) return 'high';
      if (value >= 3 && value < 4) return 'medium';
      if (value >= 4) return 'low';
      return '';
    };
    let result = 'highhighhigh';
    if (this.factorsData) {
      result =
        getRange(this.factorsData.Recon.value) +
        getRange(this.factorsData.Attack.value) +
        getRange(this.factorsData.Exfil.value);
    }
    return result === 'highhighhigh';
  }

  /*
  get priceDiffData() {
    const fullRangeDataList = this.$store.getters.stockPriceDataChart;
    const benchmark = fullRangeDataList[0];
    const percent = (val: number, zero: number) => ((val - zero) / zero) * 100;
    const from = this.stockMonthDailyList[0];
    const to = this.stockMonthDailyList[this.stockMonthDailyList.length - 1];
    const days = (date_1: Date, date_2: Date) => {
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDays;
    };
    const fromDate = new Date(from.date);
    const toDate = new Date(to.date);
    const priceChange =
      percent(to.adjusted_close, benchmark.adjusted_close) -
      percent(from.adjusted_close, benchmark.adjusted_close); // ((to.adjusted_close - from.adjusted_close) * 100) / from.adjusted_close;
    return {
      dateText: `${fromDate.toLocaleString('en', {
        month: 'short',
      })} ${fromDate.getDate()} to ${toDate.toLocaleString('en', {
        month: 'short',
      })} ${toDate.getDate()}`,
      dayDiff: days(toDate, fromDate),
      priceChange: priceChange.toFixed(0),
    };
  }

  */

  // companyMarketChange

  get priceDiffData() {
    if (!this.$store.getters.companyMarketChange) return null;
    const { NumberOfCalendarDays, PercentChange, PeriodEndDate, PeriodStartDate } =
      this.$store.getters.companyMarketChange;
    const fromDate = new Date(PeriodStartDate);
    const toDate = new Date(PeriodEndDate);
    return {
      dateText: `${fromDate.toLocaleString('en', {
        month: 'short',
      })} ${fromDate.getDate()} to ${toDate.toLocaleString('en', {
        month: 'short',
      })} ${toDate.getDate()}`,
      dayDiff: +NumberOfCalendarDays,
      priceChange: (+PercentChange).toFixed(0),
      percentChange: PercentChange,
    };
  }

  get factorsData() {
    return this.$store.getters.overview_CyFiStats;
  }

  get executiveSummaryData() {
    return this.$store.getters.executiveSummaryData;
  }

  get editorContent() {
    let rawData;
    try {
      rawData = JSON.parse(this.executiveSummaryData.EditorText).content;
    } catch {
      rawData = '';
    }
    return rawData;
  }

  get executiveSummaryUpdateDate() {
    if (this.executiveSummaryData && this.executiveSummaryData.ChTimestamp) {
      return this.formatDate(this.executiveSummaryData.ChTimestamp);
    }
    return '';
  }

  get hasExecutiveSummaryColumn(): boolean {
    return this.editorContent !== '' && this.$store.getters.isAdvisoryUser;
  }

  formatDate(date: Date): string {
    return new Date(date).toLocaleString('us');
  }

  get hasAlerts() {
    return this.$store.getters.companyRaeAlertList.length >= 1;
  }
}
