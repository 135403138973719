
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AlertWidgetSmall extends Vue {
  @Prop() private fieldName!: string;
  @Prop() private date!: Date;
  @Prop() private from!: string;
  @Prop() private to!: string;
  @Prop() private color!: number;

  get formatDate() {
    return new Date(this.date).toLocaleString('us', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }

  get colorClass() {
    if (typeof this.color === 'number') {
      return {
        high: this.color === 1,
        medium: this.color === 3,
        low: this.color === 5,
      };
    }
    return {};
  }
}
