
import { Component, Prop, Vue } from 'vue-property-decorator';
import SqrValue from '@/components/SqrValueMeta.vue';
import { CyFiStatsRowMeta } from '@/types';
import CyFiRowCurrent from './CyFi_Row_Current.vue';

@Component({
  components: {
    SqrValue,
    CyFiRowCurrent,
  },
})
export default class CyDataTable extends Vue {
  sortedField = 'ChTimestamp';
  sortedDirection: 'up' | 'down' = 'down';
  @Prop() colorOptions!: any[];

  public changeSort(field: string) {
    if (this.sortedField === field) {
      this.sortedDirection = this.sortedDirection === 'up' ? 'down' : 'up';
    } else {
      this.sortedField = field;
      this.sortedDirection = 'up';
    }
  }

  get sortedTable() {
    const initTable = [...this.tableData];
    initTable.sort((a: any, b: any) => {
      if (a[this.sortedField] > b[this.sortedField]) {
        return this.sortedDirection === 'up' ? 1 : -1;
      }
      if (a[this.sortedField] < b[this.sortedField]) {
        return this.sortedDirection === 'down' ? 1 : -1;
      }
      return 0;
    });
    return initTable;
  }

  get tableData() {
    const data = this.$store.getters.companyScores.map(
      (el: CyFiStatsRowMeta, index: number, list: CyFiStatsRowMeta[]) => {
        let MarketCapIncreased: boolean | null = true;
        if (isNaN(+el.MarketCapitalization)) {
          MarketCapIncreased = null;
        } else {
          if (index > 0) {
            MarketCapIncreased = !(
              +el.MarketCapitalization - +list[index - 1].MarketCapitalization <
              0
            );
          }
        }
        return {
          MarketCapIncreased: MarketCapIncreased,
          ...el,
        };
      }
    );
    return data;
  }

  formatCurrencyValue(value: number, dec: number = 2, power?: number): string[] {
    if (isNaN(+value)) {
      return ['no data', ''];
    }
    const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
    let counter: number = 0;
    let curValue = value;
    if (power) {
      curValue = curValue * 10 ** power;
    }
    let result: string = '';
    if (value < 0) {
      result = result + '-';
    }
    while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
      curValue = curValue / 1000;
      counter = ++counter;
    }
    result = Math.abs(curValue).toFixed(dec);
    return [result, postfix[counter] + ' $'];
  }

  get row_current() {
    const data = this.$store.getters.overview_CyFiStats;
    return {
      Title: 'Current',
      row: data,
      period: '',
    };
  }

  get row_3ma() {
    const data = this.$store.getters.overview_CyFiStats;
    return {
      Title: '3M avg',
      row: data,
      period: '_3ma',
    };
  }

  get row_12ma() {
    const data = this.$store.getters.overview_CyFiStats;
    return {
      Title: '12M avg',
      row: data,
      period: '_12ma',
    };
  }

  get row_24ma() {
    const data = this.$store.getters.overview_CyFiStats;
    return {
      Title: '24M avg',
      row: data,
      period: '_24ma',
    };
  }
}
