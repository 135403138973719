
import { Component, Vue } from 'vue-property-decorator';
import { peerbenchmarkingRoutes } from '@/router/customer-routes/peerbenchmarking-routes';

import MenuItem from './MenuItem.vue';

@Component({
  components: {
    MenuItem,
  },
})
export default class Menu extends Vue {
  get menuData() {
    return [peerbenchmarkingRoutes[0]];
  }
}
