
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MenuItem extends Vue {
  @Prop() private pageName!: string;
  @Prop() private path!: string;
  @Prop() private title!: string;

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }

  /*
  get isDisabled() {
    const result = this.$store.getters.getDisabledCompanyRoutes.find((routeName: string) => routeName === this.pageName);
    return result;
  }
*/
}
