
import { Component, Vue, Prop } from 'vue-property-decorator';
import SqrValue from '@/components/SqrValueMeta.vue';
import { CyFiStatsRowMeta } from '@/types/index';
import { Meta } from '@/types/meta';

@Component({
  components: {
    SqrValue,
  },
})
export default class CyFiRowCurrent extends Vue {
  @Prop() row?: any;
  @Prop({ default: '' }) period?: string;
  @Prop({ default: '' }) Title?: string;
  @Prop() colorOptions!: any[];
  @Prop({ default: 0 }) dec?: number;

  private colorOptionsMax3 = [
    { color: '#6464641C', condition: (val: number) => isNaN(val) },
    { color: '#D93F3059', condition: (val: number) => val >= 0 && val <= 1 },
    { color: '#ECBA4459', condition: (val: number) => val > 1 && val <= 2 },
    { color: '#3D894C80', condition: (val: number) => val > 2 && val <= 3 },
  ];

  getFieldByPeriod(fieldName: string): Meta | string {
    const key = fieldName + this.period;
    if (this.row && this.row.hasOwnProperty(key)) {
      return this.row[key];
    }
    return '-';
  }

  private formatCurrencyValue(value: number, dec: number = 2, power?: number): string[] {
    const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
    let counter: number = 0;
    let curValue = value;
    if (power) {
      curValue = curValue * 10 ** power;
    }
    let result: string = '';
    if (value < 0) {
      result = result + '-';
    }
    while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
      curValue = curValue / 1000;
      counter = ++counter;
    }
    result = Math.abs(curValue).toFixed(dec);
    return [result, postfix[counter]];
  }
}
