
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { ExecutiveSummaryRow } from '@/store/types/interfaces';
import VueSimplemde from 'vue-simplemde';

/**
 * Parse server data to editor string format
 * @param rawData Data from server
 */
function rawTextParser(rawData: string): string {
  return rawData ? rawData : '';
}

@Component({
  components: {
    VueSimplemde,
  },
})
export default class WrappedMarkdown extends Vue {
  @Prop() private readonly rawData!: any;
  @Ref() markdownViewer: any;
  content: string = '';
  configs = {
    autofocus: false,
    status: false,
    tabSize: 2,
    toolbar: false,
    toolbarTips: false,
  };
  private isEditing = false;

  beforeMount(): void {
    this.content = rawTextParser(this.rawData);
  }

  mounted() {
    this.markdownViewer.simplemde.togglePreview();
  }
}
