
import { Component, Vue } from 'vue-property-decorator';
import Table from './SubsidiariesTable.vue';
import Grid from './SubsidiariesGrid.vue';

@Component({
  components: {
    Table,
    Grid,
  },
})
export default class SubsidiariesPage extends Vue {
  public type = 'table';

  get componentName() {
    return this.type === 'table' ? 'Table' : 'Grid';
  }

  switchType(newType: string) {
    this.type = newType;
  }

  get isLoading() {
    return this.$store.getters.companySubsidiariesAreLoading;
  }

  get SubsidiariesData(): any[] {
    return this.$store.getters.companySubsidiaries;
  }
}
