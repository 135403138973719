
import { Component, Vue } from 'vue-property-decorator';
import LevelBar from '@/components/LevelBar.vue';
import { ScaledRaeData } from '@/types';
import { RaeAlertRow } from '@/store/modules/company-tabs/rae-alert';

interface ThreatDataRow extends ScaledRaeData, RaeAlertRow {}

@Component({
  components: {
    LevelBar,
  },
})
export default class ScaledRaeDataTable extends Vue {
  headers: string[] = ['LEVEL 1', 'LEVEL 2', 'LEVEL 3'];
  tableExtremes?: ScaledRaeData;
  mergedTableData: ThreatDataRow[] = [];

  private colorScheme = {
    low: {
      stroke: '#3D894C',
      fill: '#E2EDE4',
      strokeFade: '#414141',
      text: '#3D894C',
      textFade: '#434343',
    },
    mid: {
      stroke: '#F4AA6F',
      fill: '#FCEBDD',
      strokeFade: '#414141',
      text: '#ECBA44',
      textFade: '#434343',
    },
    high: {
      stroke: '#FD8E8C',
      fill: '#FBECEA',
      strokeFade: '#414141',
      text: '#D93F30',
      textFade: '#434343',
    },
  };

  created() {
    this.mergeTables();
  }

  mergeTables() {
    const timeStamp1 = this.tableData.map((el) => el.timeStamp.getTime());
    const timeStamp2 = this.tableRaeAlertData.map((el) => el.timeStamp.getTime());
    let uniq = [...new Set([...timeStamp1, ...timeStamp2])];
    uniq = uniq.filter((timestamp) => {
      const starttimestamp = new Date('2020-01-01').getTime();
      return timestamp >= starttimestamp;
    });
    const obj1 = this.tableData.reduce((pre, cur) => {
      const result = { ...pre };
      result[cur.timeStamp.getTime().toString()] = cur;
      return result;
    }, {} as { [key: string]: ScaledRaeData });
    const obj2 = this.tableRaeAlertData.reduce((pre, cur) => {
      const result = { ...pre };
      result[cur.timeStamp.getTime().toString()] = cur;
      return result;
    }, {} as { [key: string]: RaeAlertRow });
    this.mergedTableData = uniq.map((timestamp) => {
      let result: any = {};
      const key = timestamp.toString();
      if (obj1[key]) {
        result = {
          ...obj1[key],
        };
      }
      if (obj2[key]) {
        result = {
          ...result,
          ...obj2[key],
        };
      }
      return result;
    });
  }

  get columnHeight(): number {
    const result = this.mergedTableData.length * 24 + 35;
    return result;
  }

  getColorSchecme(rating: number) {
    // 1==red, 2==yellow, 3==green
    if (rating === 1) return this.colorScheme.high;
    if (rating === 2) return this.colorScheme.mid;
    if (rating === 3) return this.colorScheme.low;
  }

  getColorSchecmeFull(rating: number) {
    if (rating >= 0 && rating <= 15) return this.colorScheme.low;
    if (rating > 15 && rating <= 30) return this.colorScheme.mid;
    if (rating > 30 && rating <= 45) return this.colorScheme.high;
  }

  get tableData(): ScaledRaeData[] {
    return this.$store.getters.companyScaledRaeDataList;
  }

  get tableRaeAlertData(): RaeAlertRow[] {
    return this.$store.getters.companyRaeAlertList;
  }

  get yearCol(): string[] {
    const years: string[] = this.mergedTableData.map(
      (el: ScaledRaeData, index: number, arr: ScaledRaeData[]) => {
        const curYear = el.timeStamp.getFullYear();
        if (index === 0) {
          return curYear.toString();
        }
        const prevYear = arr[index - 1].timeStamp.getFullYear();
        if (curYear !== prevYear) {
          return curYear.toString();
        }
        return '';
      }
    );
    return years;
  }

  activeColor(value: number) {
    if (value === 0) return '#434343';
    return '';
  }
}
