
import { Component, Vue } from 'vue-property-decorator';
import CorporateWidget from '@/components/customer/CorporateWidget.vue';

@Component({ components: { CorporateWidget } })
export default class CorporateContent extends Vue {
  mockList = [
    {
      CompanyID: 'UFC8NYMNFSYIHNSM2I38_lei',
      Category: 'Departure of Officer',
      Summary: 'Appointment of Mark Dankberg as Chairman of the Board and CEO',
      StartDate: new Date('2022-07-01'),
      EndDate: new Date('2022-08-01'),
      Source: '8-K',
      SourceDate: new Date('2022-06-29'),
    },
  ];

  get corporateList() {
    return [...this.$store.getters.companyCorporateEvents].sort((a: any, b: any) => {
      const aDate = new Date(a.SourceDate);
      const bDate = new Date(b.SourceDate);
      return bDate.getTime() - aDate.getTime();
    });
  }

  get hasCorporateEvents() {
    return this.corporateList.length >= 1;
  }
}
