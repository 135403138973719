
/*
{
    "AttackPrevious": "High",
    "AttackCurrent": "High"
    "AttackCurrentColor": "1",

    "ExfilPrevious": "High",
    "ExfilCurrent": "High",
    "ExfilCurrentColor": "1",

    "ReconPrevious": "High",
    "ReconCurrent": "Medium",
    "ReconCurrentColor": "3", 
}
*/

import { Component, Vue } from 'vue-property-decorator';
import AlertWidget from '@/components/customer/AlertWidgetSmall.vue';

@Component({ components: { AlertWidget } })
export default class AlertContent extends Vue {
  mockAlertList = [
    {
      type: 'Attack',
      date: new Date(),
      from: 'medium',
      to: 'high',
      text: '4x more likely to experience +15% share price loss relative to market',
    },
    {
      type: 'Reconnaissance',
      date: new Date().setMonth(new Date().getMonth() - 1),
      from: 'low',
      to: 'medium',
      text: '4x more likely to experience +15% share price loss relative to market',
    },
  ];

  get alertList() {
    const list = this.$store.getters.companyCyberAlerts;
    const resultList: any[] = [];
    list.forEach((el: any) => {
      const { AttackPrevious, AttackCurrent, AttackCurrentColor } = el;
      if (AttackPrevious !== AttackCurrent) {
        const attack = {
          fieldName: 'Attack',
          color: +AttackCurrentColor,
          from: AttackPrevious,
          to: AttackCurrent,
          date: new Date(el.Date),
        };
        resultList.push(attack);
      }

      const { ExfilPrevious, ExfilCurrent, ExfilCurrentColor } = el;
      if (ExfilPrevious !== ExfilCurrent) {
        const exfil = {
          fieldName: 'Exfil',
          color: +ExfilCurrentColor,
          from: ExfilPrevious,
          to: ExfilCurrent,
          date: new Date(el.Date),
        };
        resultList.push(exfil);
      }

      const { ReconPrevious, ReconCurrent, ReconCurrentColor } = el;
      if (ReconPrevious !== ReconCurrent) {
        const recon = {
          fieldName: 'Reconnaissance',
          color: +ReconCurrentColor,
          from: ReconPrevious,
          to: ReconCurrent,
          date: new Date(el.Date),
        };
        resultList.push(recon);
      }
    });
    const sortedList = resultList.sort((a, b) => b.date.getTime() - a.date.getTime());
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    console.warn('alert list showed after:', startDate);
    const filteredList = sortedList.filter((el) => {
      return el.date >= startDate;
    });
    return filteredList;
  }

  get hasAlerts() {
    return this.alertList.length >= 1;
  }
}
