import { render, staticRenderFns } from "./MarkdownViewer.vue?vue&type=template&id=3e1e8a3d&"
import script from "./MarkdownViewer.vue?vue&type=script&lang=ts&"
export * from "./MarkdownViewer.vue?vue&type=script&lang=ts&"
import style0 from "./MarkdownViewer.vue?vue&type=style&index=0&id=3e1e8a3d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports